const headerMenu  = () => {
  let menutogbtn = document.querySelector('.js-header-menu');
  let navmenu = document.querySelector('.js-header-nav');
  
    menutogbtn?.addEventListener('click', () => {
    menutogbtn?.classList.toggle('active');
    navmenu?.classList.toggle('active');
    });
};

export default headerMenu;
const headerScroll  = () => {
  const logoh = document.querySelector('.js-header__logo-img');
  
  window.addEventListener('scroll', function () {
    if (window.pageYOffset > 300) {
      logoh?.classList.add('scrolled');
    } else {
      logoh?.classList.remove('scrolled');
    }
  });
};
  
  export default headerScroll;
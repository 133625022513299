import test from './components/test';
import headerMenu from './components/headerMenu';
import headerScroll from './components/headerScroll';
import scrollTop from './components/scrollTop';

document.addEventListener(
  'DOMContentLoaded',
  () => {
    test();
    headerMenu();
    headerScroll();
    scrollTop();
  },
  false
);

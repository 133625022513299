const scrollTop = () => {
  const btn = document.querySelector('.js-page-top');

  if (btn) {
    // Add scroll event listener to window
    window.addEventListener('scroll', function () {
      // Check if scroll position is greater than 300
      if (window.scrollY > 300) {
        // Add 'show' class to button
        btn.classList.add('show');
      } else {
        // Remove 'show' class from button
        btn.classList.remove('show');
      }
    });

    // Add click event listener to button
    btn.addEventListener('click', function (e) {
      e.preventDefault();
      // Scroll to top smoothly
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    });
  } else {
    console.error('Button element with id "button" not found.');
  }
};

export default scrollTop;